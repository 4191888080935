.card-app-container{
    border:2px solid black;
    display: flex;
    flex-direction: column !important;
}
.card-element{
    border-bottom: 1px solid  black;
    padding: 5px ;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.element-description{
    margin: 0px 5px;
    margin-left: 10px !important;
}
.element-left-pannel{
    display: flex;
    align-items: center;
}