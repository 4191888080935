.container{
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column !important;
}
.card-container{
    margin-top: 40px !important;
    display: flex !important;
    justify-content: center;
    align-items: flex-start;
    min-width: 500px;
    flex-direction: column !important;
}