.logo_container{
    height: 100px;
    background-color:#005984;
    display: flex;
    justify-content: center;
    .logo{
        height: 100px;
        width: 130px;
    }
}
.middle_container{
    border-right: solid white;
    border-left: solid white;
}
.person_icon{
    svg{
        height: 60px;

    }
}